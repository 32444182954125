<!-- <div *ngIf="!fullbodychecker">  -->
<div class="footer" *ngIf="showFooter$ | async">
  <div class="footercenter">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-3 col-md-12 col-12 greyverticle">
        <div class="footercolumn">
          <a routerLink="/"><img [lazyLoad]="image82" [defaultImage]="defaultImage" class="img-fluid" width="80" height="73" alt="Dadumedicalcentre"/></a>
          <p>Dadu Medical Centre has become the most trusted skin clinic in Delhi given to its world class
            dermatology treatments, highly professional and amiable dermatologists, aestheticians and nurses. The
            clinic located in Vasant Vihar (South Delhi), Rajouri Garden (West Delhi), Delhi offers a wide range of clinical and cosmetic dermatology services.</p>
          <div class="footerline"></div>
        </div>
        <div class="footercolumnss">
          <div class="ftheading"><a routerLink="/contact-us">Contact Us</a></div>
          <ul>
            <li>A2/6 Vasant Vihar, New Delhi, 110057</li>
            <li class="space15"></li>
            <li>J-12/25, First Floor, Rajouri Garden,<br />
              New Delhi -110027, India</li>
            <li class="space15"></li>
            <li>
              Phone: <a href="tel:+919810939319"> +91-981-093-9319</a>,<br />
              <span><a href="tel:+919667721501">+91-966-772-1501</a></span>
            </li>
            <li class="space15"></li>
            <li>Email:
              <a href="mailto:info@dadumedicalcentre.com">info@dadumedicalcentre.com</a></li>
          </ul>
          <div class="footerline"></div>
        </div>
        <div class="footercolumns">
          <div class="smheading">Follow Us On</div>
          <ul class="social-network social-circle">
            <li>
              <a href="https://www.facebook.com/daduskinclinic/" class="icoFacebook" title="Facebook" aria-label="facebook" rel="nofollow"
                target="_blank"><i class="fa fa-facebook"></i></a>
            </li>
            <li>
              <a href="https://www.instagram.com/dadumedicalcentre/" class="icoInstagram" aria-label="instagram" title="Instagram"
                rel="nofollow" target="_blank"><i class="fa fa-instagram"></i></a>
            </li>
            <li>
              <a href="https://twitter.com/daduskinclinic" class="icoTwitter" title="Twitter" aria-label="twitter" rel="nofollow"
                target="_blank"><i class="fa fa-twitter"></i></a>
            </li>
            <li>
              <a href="https://youtube.com/c/DaduMedicalCentre" class="icoYoutube" title="Youtube" aria-label="youtube" rel="nofollow"
                target="_blank"><i class="fa fa-youtube"></i></a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/dadu-medical-centre/" class="icoLinkedin" aria-label="linkedin" title="Linkedin"
                rel="nofollow" target="_blank"><i class="fa fa-linkedin"></i></a>
            </li>
            <li>
              <a href="https://in.pinterest.com/daduskinclinic/" class="icoPinterest" title="Pinterest" aria-label="pinterest" rel="nofollow"
                target="_blank"><i class="fa fa-pinterest"></i></a>
            </li>
            <li>
              <a href="https://soundcloud.com/daduskinclinic" class="icoSoundcloud" title="Soundcloud" aria-label="soundcloud" rel="nofollow"
                target="_blank"><i class="fa fa-soundcloud"></i></a>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-9 col-md-12 col-12">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-12">
            <div class="fheading">
              <img [lazyLoad]="image83" [defaultImage]="defaultImage" width="19" height="4" alt="Dadumedicalcentre" />
              Our Services
            </div>
          </div>

          <div class="col-lg-12 col-md-12 col-12">
            <div class="footercolumns">
              <div class="ftheading">Useful Links</div>
              <ul>
                <li><a routerLink="/" routerLinkActive="active">Home</a></li>
                <li><a routerLink="/our-doctors" routerLinkActive="active">Our Doctors</a></li>
                <li>
                  <a [routerLink]="['/dr-nivedita-dadu']" routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }">Dr. Nivedita Dadu</a>
                </li>
                <li>
                  <a [routerLink]="['/dr-nandini-dadu']" routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }">Dr. Nandini Dadu</a>
                </li>
                <li>
                  <a [routerLink]="['/about-clinic']" routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }">About Clinic</a>
                </li>
                <li><a [routerLink]="['/dadu-medical-academy']" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Dadu Medical Academy</a></li>                
                <li><a href="https://www.dadumedicalcentre.com/dmcbodylounge" target="_blank">DMC Body Lounge<sup>&#174;</sup></a></li>
                <li><a [routerLink]="['/dadu-medical-academy']" routerlinkactive="active-link" [routerLinkActiveOptions]="{ exact: true }">Dadu Medical Academy</a></li>
                <li>
                  <a [routerLink]="['/testimonials']" routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }">Testimonials</a>
                </li>
                <li>
                  <a [routerLink]="['/gallery']" routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }">Gallery</a>
                </li>
                <li>
                  <a [routerLink]="['/results']" routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }">Results</a>
                </li>
                <li>
                  <a [routerLink]="['/videos']" routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }">Videos</a>
                </li>
                <li>
                  <a [routerLink]="['/technology']" routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }">Technology</a>
                </li>
                <li>
                  <a [routerLink]="['/press-media']" routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }">Press & Media</a>
                </li>
                <li>
                  <a [routerLink]="['/contact-us']" routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }">Contact Us</a>
                </li>
                <li><a routerLink="/careers" routerLinkActive="active">Careers</a></li>
                <li>
                  <a [routerLink]="['/request-consultation']" routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }">Offers</a>
                </li>
                <li>
                  <a [routerLink]="['/blogs']" routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }">Blogs</a>
                </li>
                <li>
                  <a [routerLink]="['/influencer-testimonials']" routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }">Influencers</a>
                </li>
                <li>
                  <a [routerLink]="['/dermatologist-in-delhi']" routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }">Dermatologist in Delhi</a>
                </li>
                <li>
                  <a [routerLink]="['/skin-specialist']" routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }">Skin Specialist</a>
                </li>
              </ul>
            </div>
            <div class="footerline"></div>
          </div>
          <div class="col-lg-12 col-md-12 col-12">
            <div class="footercolumn">
              <div class="ftheading"><a routerLink="/treatments" routerLinkActive="active">Treatments</a></div>
              <ul>
                <li>
                  <a routerLink="/treatments/acne-pimple">Acne/Pimple</a>
                </li>
                <li>
                  <a routerLink="/treatments/acne-scars">Acne Scars</a>
                </li>
                <li>
                  <a routerLink="/treatments/skin-glow">Skin Glow</a>
                </li>
                <li>
                  <a routerLink="/treatments/pigmentation">Pigmentation</a>
                </li>
                <li>
                  <a routerLink="/treatments/hairfall">Hairfall/Hair Loss</a>
                </li>
                <li>
                  <a routerLink="/treatments/unwanted-hair">Unwanted Hair</a>
                </li>
                <li>
                  <a routerLink="/treatments/anti-aging">Anti-Aging</a>
                </li>
                <li>
                  <a routerLink="/treatments/dark-circles">Dark Circles</a>
                </li>
                <li>
                  <a routerLink="/treatments/dark-lips">Dark Lips</a>
                </li>
                <li><a routerLink="/treatments/stretch-marks">Stretch Marks</a></li>
                <li><a routerLink="/treatments/non-surgical-facial-enhancement">Non-Surgical Facial Enhancement</a></li>
                <li><a routerLink="/treatments/iv-infusion">IV Infusions</a></li>
                <li><a routerLink="/treatments/freckles">Freckles</a></li>
                <li><a routerLink="/request-consultation">Tattoo Removal </a></li>
              </ul>
            </div>
            <div class="footerline"></div>
          </div>

          <div class="col-lg-12 col-md-12 col-12">
            <div class="footercolumn">
              <div class="ftheading"><a routerLink="/conditions" routerLinkActive="active">Conditions</a></div>
              <div class="footercolumn">
                <div class="fborangeheading"><a routerLink="/conditions/medical" routerLinkActive="active"><i class="fa fa-angle-right" aria-label="Medical"></i> Medical</a></div>
                <ul>
                  <li><a routerLink="/atopic-dermatitis">Atopic Dermatitis</a></li>
                  <li><a routerLink="/psoriasis">Psoriasis</a></li>
                  <li><a routerLink="/warts">Warts</a></li>
                  <li><a routerLink="/molluscum-contagiosum">Molluscum Contagiosum</a></li>
                  <li><a routerLink="/corn-and-callus">Corn and Callus</a></li>
                  <li><a routerLink="/ingrown-toenails">Ingrown toenails</a></li>
                  <li><a routerLink="/urticaria">Urticaria</a></li>
                  <li><a routerLink="/fungal-infections">Fungal Infection</a></li>
                  <li><a routerLink="/acanthosis-nigricans">Acanthosis Nigricans</a></li>
                  <li><a routerLink="/rosacea-treatment">Rosacea</a></li>
                  <li><a routerLink="/vitiligo">Vitiligo</a></li>
                  <li><a routerLink="/dpn">DPN</a></li>
                  <li><a routerLink="/skin-growth">Skin Growth</a></li>
                  <li><a routerLink="/sexually-transmitted-diseases">STD</a></li>
                  <li><a routerLink="/nail-avulsion">Nail avulsion</a></li>
                  <li><a routerLink="/acne">Acne</a></li>
                  <li><a routerLink="/contact-dermatitis">Contact Dermatitis</a></li>
                  <li><a routerLink="/pcod">PCOD</a></li>
                  <li><a routerLink="/seborrheic-dermatitis">Seborrheic Dermatitis</a></li>
                  <li><a routerLink="/birthmarks-treatment">Birth Marks</a></li>
                  <li><a routerLink="/lichen-planus">Lichen Planus</a></li>
                  <li><a routerLink="/eczema">Eczema</a></li>
                  <li><a routerLink="/skin-tags">Skin Tags</a></li>
                  <li><a routerLink="/sensitive-skin">Sensitive Skin</a></li>
                  <li><a routerLink="/cyst-removal">Cyst Removal</a></li>
                  <li><a routerLink="/request-consultation">Keloid</a></li>
                  <li><a routerLink="/request-consultation">Spider veins</a></li>
                </ul>
              </div>

              <div class="footercolumn">
                <div class="fborangeheading">
                  <a routerLink="/conditions/aesthetics" routerLinkActive="active"><i class="fa fa-angle-right" aria-label="Aesthetics"></i> Aesthetics</a>
                </div>
                <ul>
                  <li><a routerLink="/acne">Acne</a></li>
                 <li><a routerLink="/open-pores">Open Pores</a></li>
                  <li><a routerLink="/acne-scar-treatment">Acne Scar Treatment</a></li>
                  <li><a routerLink="/melasma-treatment">Melasma Treatment</a></li>
                  <li><a routerLink="/pigmentation-treatment">Pigmentation Treatment</a></li>
                  <li><a routerLink="/wrinkle-removal">Wrinkles Removal</a></li>
                  <li><a routerLink="/sagging-skin">Sagging Skin</a></li>
                  <li><a routerLink="/tattoo-removal">Tattoo Removal</a></li>
                  <li><a routerLink="/request-consultation">Freckles Treatment</a></li>
                  <li><a routerLink="/request-consultation">fine lines</a></li>
                  <li><a routerLink="/request-consultation">Dark Circles Treatment</a></li>
                  <li><a routerLink="/request-consultation">Open Pores Treatment</a></li>
                  <li><a routerLink="/request-consultation">Stretch Mark Removal</a></li>
                  <li><a routerLink="/request-consultation">Body Hair</a></li>
                  <li><a routerLink="/request-consultation">Dark Lips</a></li>
                </ul>
              </div>

              <div class="footercolumn">
                <div class="fborangeheading">
                  <a routerLink="/conditions/hair" routerLinkActive="active"><i class="fa fa-angle-right" aria-label="Hair"></i> Hair</a>
                </div>
                <ul>
                  <li><a routerLink="/hair-fall-in-men">Hairfall in Men</a></li>
                  <li><a routerLink="/premature-greying">Premature Greying</a></li>
                  <li><a routerLink="/alopecia-areata">Alopecia Areata</a></li>
                  <li><a routerLink="/dandruff">Dandruff</a></li>
                  <li><a routerLink="/hairfall-in-women">Hairfall in Women</a></li>
                  <li><a routerLink="/scarring-alopecia">Scarring Alopecia</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-12 col-md-12 col-12">
        <div class="footerline"></div>
        <div class="disclaimer">
          <p>
            <strong>Disclaimer:</strong> The content on this website (<a href="https://www.dadumedicalcentre.com/"
              target="_blank">www.dadumedicalcentre.com</a>) is solely for the purpose of educating and creating
            awareness
            about the domain i.e. Dermatology. This shall not be treated as a
            substitute to a professional dermatologist's advice or prescription.
            There is no guarantee of specific results and the results for any
            treatment mentioned on the website may vary, as every individual and
            their skin conditions are different.
          </p>
        </div>
      </div>
    </div>
  </div>
  </div>

  <!-- Start of copyright -->
<div class="copyright">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-5 col-md-12 col-12">
        <div class="copyrightleft">
          <a routerLink="/terms-conditions">Terms &amp; Conditions</a> |
          <a routerLink="/legal-disclaimer">Legal Disclaimer</a> |
          <a routerLink="/privacy-policy">Privacy Policy</a> | <a routerLink="/payment-refund-policy">Payment & Refund
            Policy</a>
        </div>
      </div>
      <div class="col-lg-7 col-md-12 col-12">
        <div class="copyrightright">
          Copyright &copy; {{previousYear}} - {{currentYear}}, Dadu Medical Centre | All Rights Reserved. Powered by <a
            href="https://www.digilantern.com/" target="_blank">DigiLantern</a>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- // End of copyright -->

<!-- Start of bottom to top fixed -->
<button class="bottomtop" *ngIf="isShow" aria-label="Top" (click)="gotoTop()">
  <i class="fa fa-arrow-up" ></i>
</button>
<!-- // End of bottom to top fixed -->

<div class="button-parent d-sm-block d-md-none d-lg-none">
  <input type="checkbox" aria-label="Phone" id="btn">
  <label for="btn" class="buttonfix">
    <i class="fa fa-phone"></i>
  </label>
  <div class="link-parent">
    <a href="tel:+919810939319" class="link-item" aria-label="Phone" id="link-one">
      +91-981-093-9319 <span class="fa fa-phone"></span>
    </a>
    <a href="tel:+919667721501" class="link-item" aria-label="Phone" id="link-two">
      +91-966-772-1501 <span class="fa fa-phone"></span>
    </a>
  </div>
</div>

<!-- Start of bottom_fixed -->
<div class="bottom_fixed d-sm-block d-md-none d-lg-none">
  <div class="bicon-bar">
    <a routerLink="/request-consultation" aria-label="Request A Consultation"><i class="fa fa-calendar"></i> Request A Consultation</a>
  </div>
  <div class="bicon-bar2">
    <a href="mailto:info@dadumedicalcentre.com" target="_blank" aria-label="Write to Us"><i class="fa fa-envelope"></i> Write to Us</a>
  </div>
</div>
<!-- // End of bottom_fixed -->

<!-- <a target="_blank" title="Dadumedicalcentre"
  href="https://api.whatsapp.com/send?phone=+919810939319&text=Hello, Dadumedicalcentre" aria-label="whatsapp" class="whatsapplink"><i
    class="fa fa-whatsapp" ></i></a> -->
<!-- // End of footer -->
<!-- </div>  -->